import React, { Suspense, useContext, useEffect } from "react"

import { isSSR } from "../utils/constants"
import Layout from "../components/layout"
import SEO from "../components/Seo"
import Spinner from "../components/Spinner"
import Resource from "../config/language.json"
import AdditionalResource from "../components/HomePage/language.json"
import { GlobalStateContext } from "../context/GlobalContextProvider"

const ThankYouPage = React.lazy(() => import("../components/ThankYouPage"))
const component = Resource[process.env.GATSBY_SITE_VERSION]
const additionalComponent = AdditionalResource[process.env.GATSBY_SITE_VERSION]

export default () => {
  const state = useContext(GlobalStateContext)

  useEffect(() => {
    if (state.loanType && window.fbq) {
      window.fbq("track", "CompleteRegistration", {
        value: 1,
        currency: `${additionalComponent.currency}`,
      })
    }
  }, [state.loanType])

  return (
    <>
      {!isSSR && (
        <Suspense fallback={<Spinner />}>
          <SEO
            title="Gracias referidor"
            keywords={["Referir, dinero, bono por referir"]}
            description="Referido de iban online"
          />
          <Layout>
            <ThankYouPage
              title={component.thanksRefer.title}
              secondTitle={component.thanksRefer.secondTitle}
              list={component.thanksRefer.list}
              text={component.thanksRefer.text}
            />
          </Layout>
        </Suspense>
      )}
    </>
  )
}
